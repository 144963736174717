<template>
  <div align="center">
    <b-card no-body align="center" style="width: 520px">
      <img
        :src="require('@/assets/images/logo/thai_qr_payment.png')"
        width="100%"
      />
      <div v-for="item in data" :key="item.name">
        <h2> แพ็กเกจ &nbsp;{{ item.name }}</h2>
        <h3>จำนวนเงิน {{ new Intl.NumberFormat("en-IN").format(amount) }} บาท</h3>
        <!-- <b> -->
        <!-- <p>{{ item.title }}</p></b> -->
      </div>

      <qrcode-vue :value="res.data.qrUrl" size="300" level="L" />

      <h4 class="mt-4">
        หลังจากจ่ายเงินผ่าน QR เรียบร้อยแล้วกรุณารอสักครู่.......
      </h4>
    </b-card>
    <b-modal
      id="modal-primary"
      ok-only
      ok-title="ตกลง"
      ok-variant="success"
      modal-class="modal-primary"
      centered
      title="ใบกำกับภาษี"
      v-model="model_invoice"
      hide-header-close
      hide-footer
      no-close-on-backdrop
    >
      <b-card-text>
        <input
          id="option-one-all3"
          v-model="tax_invoice"
          value="no"
          checked="checked"
          type="radio"
        />
        <label for="option-one-all3"> <span></span> ไม่รับใบกำกับภาษี </label>
        <input
          id="option-one-all4"
          v-model="tax_invoice"
          value="yes"
          checked="checked"
          type="radio"
        />
        <label for="option-one-all4"> <span></span> รับใบกำกับภาษี</label>
      </b-card-text>

      <div align="right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-4"
          @click="click()"
        >
          ตกลง
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BImg,
  BCardText,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BRow,
  BCol,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import QrcodeVue from "qrcode.vue";
import api from "@/api";

export default {
  components: {
    BCard,
    QrcodeVue,
    BImg,
    BCardText,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BRow,
    BCol,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      model_invoice: false,
      tax_invoice: "no",
      qrshow: false,
      amount: 0,
      timer: null,
      res: {
        data: { qrUrl: null },
      },
      tax_data: 0,
      total_day: 0,
      price: [],
      data: [],
      expiry_date: [],
      type: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.model_invoice = true;
    this.package_price();
    if (this.$route.query.status === "monthly") {
      this.type = " 30 วัน";
    } else {
      this.type = "365 วัน";
    }
  },
  methods: {
    package_price() {
      const params = {
        id: this.$route.query.id,
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("package_price", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("response.data :>> ", response.data);
          this.tax_data = response.data["tax_data"];
          this.price = response.data[this.$route.query.id];

          // this.amount = response.data[this.$route.query.id];
          // this.click();
          if (this.$route.query.id === "1y" || this.$route.query.id === "1m") {
            this.data = response.data.data1;
          } else if (
            this.$route.query.id === "2y" ||
            this.$route.query.id === "2m"
          ) {
            this.data = response.data.data2;
          } else {
            this.data = response.data.data3;
          }
          if (this.$route.query.status === "monthly") {
            this.amount = this.data[0].tax_m;
            this.total_day = 30;
          } else {
            this.amount = this.data[0].tax_y;
            this.total_day = 365;
          }
          // console.log("this.tax_data :>> ", this.tax_data);
          // console.log(" this.data  :>> ", this.data);
        })
        .catch((error) => {});
    },
    click() {
      this.model_invoice = false;
      // console.log("this.amount :>> ", this.amount);

      if (this.tax_data === 0 && this.tax_invoice === "yes") {
        console.log(" กรอกข้อมูลใบกำกับภาษี:>> ");
        this.$router.push("/TaxInvoice");
      } else {
        if (this.amount > 0) {
          const params = {
            amount: this.amount,
            // amount: 1,
            user_id: localStorage.getItem("store_id"),
            store_id: localStorage.getItem("store_id"),
            tax_invoice: this.tax_invoice,
            package: this.data[0].name,
            total_day:this.total_day
          };
          api
            .post("/createpayment", params)
            .then((response) => {
              this.qrshow = true;
              this.res = response.data;

              this.timer = setInterval(() => {
                api
                  .post("/checkpayment", this.res.data)
                  .then((response2) => {
                    console.log(response2.data);

                    if (response2.data == "OK") {
                      // alert("ok")
                      clearInterval(this.timer);
                      this.$bvToast.toast("ฝากเงินสำเร็จ", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                      });
                      localStorage.setItem("oneSelect", 1);
                      window.location.href =
                        window.location.origin + "/PaymentHistory";
                      // window.location.href = "https://payment.socio.co.th/";
                    }
                  })
                  .catch((error2) => {
                    // router.push('/pages/not-authorized');
                  });
              }, 5000);
            })
            .catch((error) => {
              // router.push('/pages/not-authorized');
            });
        } else {
          this.$bvToast.toast("กรุณาป้อนจำนวนเงินที่ต้องการจะฝาก", {
            title: "เกิดข้อผิดพลาด!",
            variant: "danger",
            solid: true,
          });
        }
      }
    },
  },
};
</script>

<style>
</style>
